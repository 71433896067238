<template>
  <section id="pricing-plan">
    <div id="tour-downloads">
      <!-- title text and switch button -->
      <div class="text-center mb-2">
        <h1 class="mt-5">
          {{ $t('Downloads') }}
        </h1>
        <p class="mb-2 pb-75">
          {{ $t('downloads.download-info') }}
        </p>
      </div>
      <b-overlay :show="isLoading">
        <b-row class="pricing-card">
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row class="d-flex justify-content-center">
              <template v-for="(app, index) of selectedApplications">
                <b-col :key="index">
                  <b-card
                    class="popular position-relative"
                    align="center"
                  >
                    <b-overlay :show="loadingApplication === index">
                      <b-img
                        :src="getImages(index)"
                        class="mb-1"
                        :style="{ height: '100px' }"
                        alt="svg img"
                      />

                      <b-form-select
                        v-model="selectedVersions[index]"
                        v-b-tooltip.hover="'Select Version'"
                        class="position-absolute version-options w-25 badge badge-primary cursor-pointer"
                        @input="getApplication(app.data[0].platform)"
                      >
                        <b-form-select-option
                          v-for="{version} of applicationsBuilds[app.data[0].platform]"
                          :key="version"
                          :value="version"
                        >
                          {{ version }}
                        </b-form-select-option>
                      </b-form-select>

                      <h3 class="text-capitalize">
                        {{ app.data[0].platform }}
                      </h3>
                      <b-card-text>{{ getSubtitle(index) }}</b-card-text>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <sup
                            class="font-medium-1 font-weight-bold text-primary"
                            :style="{ marginRight: '4px' }"
                          >V</sup>
                          <span
                            class="pricing-basic-value font-weight-bolder text-primary"
                          >{{ app.data[0].version }}</span>
                        </div>
                      </div>

                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        block
                        target="_blank"
                        class="mt-2"
                        variant="primary"
                        @click="downloadApp(app)"
                      >
                        {{ $t('actions.download') }}
                      </b-button>
                    </b-overlay>
                  </b-card>
                </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BButton,
  BOverlay,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useApollo from '@/plugins/graphql/useApollo'
import { ARCHITECTURES, getImages, getSubtitle } from './useDownloads'
import { setComponentReady } from '../tour/tour'

export default {
  components: {
    BButton,
    BCardText,
    BRow,
    BCol,
    BCard,
    BOverlay,
    BImg,
    BFormSelect,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      applications: [],
      architectureList: ARCHITECTURES,
      versions: [],
      selectedVersions: [],
      applicationsBuilds: [],
      filteredApplications: [],
      selectedApplications: [],
      isLoading: false,
      loadingApplication: null,
    }
  },
  mounted() {
    this.getApplicationBuild()
    this.getApplications()
  },
  methods: {
    getImages,
    getSubtitle,
    getApplications() {
      useApollo.company.getApplication().then(response => {
        this.selectedApplications = response.data
        this.selectedVersions = {
          MAC: this.selectedApplications.MAC.data[0].version,
          WINDOWS: this.selectedApplications.WINDOWS.data[0].version,
          LINUX: this.selectedApplications.LINUX.data[0].version,
        }
      })
    },
    getApplicationBuild() {
      this.isLoading = true
      useApollo.company.getApplicationBuild().then(response => {
        this.applicationsBuilds = response.data
      }).finally(() => {
        this.isLoading = false
        setComponentReady()
      })
    },
    getApplication(platform) {
      this.loadingApplication = platform
      useApollo.company
        .getApplicationBuildByVersion({
          platforms: platform,
          version: this.selectedVersions[platform],
        })
        .then(response => {
          this.selectedApplications[platform] = response.data.applications
        }).finally(() => {
          this.loadingApplication = null
        })
    },
    downloadApp(application) {
      const downloadLink = application.data[0]
      if (!downloadLink) {
        this.showError('No Data found')
      }
      const a = document.createElement('a')
      a.href = downloadLink.filename
      a.download = downloadLink.filename.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
  },
}
</script>

<style scoped lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
.pricing-card {
  min-height: 320px;
}
.version-options {
  top: 12px;
  right: 12px;
}
</style>
